import React from "react";

export function AtSign(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#6C6C73"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 8.00011V13.0001C16 13.7958 16.3161 14.5588 16.8787 15.1214C17.4413 15.684 18.2044 16.0001 19 16.0001C19.7957 16.0001 20.5587 15.684 21.1213 15.1214C21.6839 14.5588 22 13.7958 22 13.0001V12.0001C21.9999 9.74314 21.2362 7.55259 19.8333 5.78464C18.4303 4.0167 16.4706 2.77534 14.2726 2.26241C12.0747 1.74948 9.76794 1.99515 7.72736 2.95948C5.68677 3.9238 4.03241 5.55007 3.03327 7.57383C2.03413 9.5976 1.74898 11.8998 2.22418 14.1062C2.69938 16.3126 3.90699 18.2933 5.65064 19.7264C7.39429 21.1594 9.57144 21.9605 11.8281 21.9993C14.0847 22.0381 16.2881 21.3124 18.08 19.9401"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
